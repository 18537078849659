.formContainer {
  background-image: url("https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2948&q=80");
  background-size: cover;
  background-position: 0 -280px;
  background-repeat: no-repeat;
  width: 100%;
}

.formContent {
  background-color: rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "dropdown1 dropdown1 dropdown2 dropdown2"
    "instructions instructions instructions instructions"
    "upload upload upload upload"
    "object object object object"
    "error error error error"
    ". . . button";
  height: 100%;
  padding: 8vh 17vw;
  width: 100%;
}

.dropdown1 {
  grid-area: dropdown1;
}

.dropdown2 {
  grid-area: dropdown2;
  justify-self: end;
}

.upload {
  grid-area: upload;
}

.object {
  grid-area: object;
  display: flex;
  width: 100%;
  background-color: white;
  justify-content: space-between;
  padding: 0 2em;
  font-size: 1.1rem;
  align-items: center;
  border-radius: 22px;
  margin-top: 1em;
}

.button {
  grid-area: button;
  justify-self: end;
  margin-top: 1em;
}

.instructions {
  background-color: rgba(0, 0, 0, 0.65);
  grid-area: instructions;
  color: white;
  padding: 1em;
  border: solid 1px white;
  margin: 1em 0;
}

.error {
  grid-area: error;
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  padding: 1em;
  border: solid 1px red;
  margin: 1em 0;
}

@media (max-width: 1300px) {
  .formContainer {
    background-position: 0 -180px;
  }
}

@media (max-width: 1200px) {
  .formContainer {
    background-position: 0 -80px;
  }
}

@media (max-width: 1100px) {
  .formContainer {
    background-position: 0 0;
  }
}

@media (max-width: 700px) {
  .formContent {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "dropdown1"
      "dropdown2"
      "instructions"
      "upload"
      "object"
      "error"
      "button";
  }

  .dropdown2 {
    justify-self: start;
    margin-top: 1em;
  }
}
