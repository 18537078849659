.container {
  width: 100%;
  background-color: white;
  border-radius: 22px;
  height: 10em;
  display: grid;
  grid-template-rows: 65% 35%;
  position: relative;
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.label {
  background-color: #1cd4e3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  font-size: 1.7rem;
  cursor: pointer;
  color: white;
  z-index: 1;
}

.text {
  color: #dedede;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 0.8em;
}

@media (max-width: 500px) {
  .text {
    font-size: 2rem;
    margin-top: 1.2em;
  }
}
