.container {
  background-color: #e6fafc;
  padding: 2rem 4rem;
}

.headerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dayBasedHeaderContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.header {
  font-size: 1.2rem;
  justify-self: center;
  margin: 0;
}

.dayBasedHeader {
  font-size: 1.2rem;
  justify-self: center;
  margin: 0;
}

@media (max-width: 500px) {
  .headerContainer {
    grid-template-columns: 1fr 1fr;
  }

  .header:nth-of-type(2) {
    display: none;
  }

  .header:nth-of-type(3) {
    display: none;
  }

  .container {
    padding: 2rem 2rem;
  }
}
