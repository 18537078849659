.table {
  width: 100vw;
  padding: 1rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.thead {
  font-size: 2rem;
  text-align: center;
  background-color: #1cd4e3;
  color: white;
}

.thead tr td:first-child {
  text-align: left;
  border-top-left-radius: 8px;
  padding-left: 1rem;
}

.thead tr td:last-child {
  text-align: left;
  border-top-right-radius: 8px;
  padding-left: 1rem;
}

.thead tr td {
  padding-top: 0.5rem;
}

.tbody {
  font-size: 1.5rem;
}

.tbody tr {
  border-bottom: 1px solid #dddddd;
}

.tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.tbody td {
  padding: 12px 15px;
}
