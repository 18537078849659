.container {
  display: flex;
  margin-bottom: 2.5rem;
}

.arrow {
  align-self: end;
  fill: #1cd4e3;
  margin-left: 3rem;
  margin-right: 3rem;
  padding-bottom: 10px;
  width: 2.5rem;
}

.buttonContainer {
  align-self: end;
  margin-left: 9rem;
}

.checkBoxContainer {
  margin-bottom: 2em;
}

@media (max-width: 700px) {
  .arrow {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 2rem;
  }

  .buttonContainer {
    align-self: end;
    margin-left: 2rem;
  }
}

@media (max-width: 500px) {
  .container {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .buttonContainer {
    align-self: end;
    margin-top: 2rem;
  }

  .arrow {
    display: none;
  }
}
