.formContainer {
  background-image: url("https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2948&q=80");
  background-size: cover;
  background-position: 0 -300px;
  background-repeat: no-repeat;
  width: 100%;
}

.formContent {
  background-color: rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  padding: 14vh 17vw;
  width: 100%;
}

.alignRight {
  justify-self: end;
}

.buttonContainer {
  justify-self: end;
}

.error {
  bottom: 120px;
  color: black;
  font-size: 2rem;
  margin: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

@media (max-width: 1300px) {
  .formContainer {
    background-position: 0 -200px;
  }
}

@media (max-width: 1200px) {
  .formContainer {
    background-position: 0 -100px;
  }
}

@media (max-width: 1100px) {
  .formContainer {
    background-position: 0 0;
  }
}

@media (max-width: 700px) {
  .formContent {
    grid-template-columns: 1fr;
  }

  .alignRight {
    justify-self: start;
  }

  .buttonContainer {
    justify-self: start;
  }
}
