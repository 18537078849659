.container {
  padding: 2rem 4rem;
  width: 100%;
}

.sectionHeader {
  font-size: 5rem;
  margin: 0;
}

@media (max-width: 500px) {
  .container {
    padding: 2rem 2rem;
  }
}
