.container {
  animation: toast-in-right 0.7s;
  background-color: #1cd4e3;
  border-radius: 11px;
  bottom: 12px;
  color: white;
  fill: white;
  position: fixed;
  transition: all 0.6s ease-in-out;
}

.checkmarkContainer {
  align-items: center;
  border: 1px solid white;
  border-radius: 100%;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-right: 0.6rem;
  width: 20px;
}

.titleContainer {
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  margin-top: 1.6rem;
  width: 100%;
}

.title {
  margin: 0;
}

.message {
  font-size: 1.2rem;
  margin: 1em;
}

.remove {
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 2px;
}
