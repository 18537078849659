.container {
  display: grid;
  /* grid-template-columns: 1fr 2fr 3fr 1fr 1fr; */
  grid-template-columns: 1fr 2fr 3fr 1fr;
}

.entry {
  border: 1px solid black;
  padding: 0.2em;
}
