@font-face {
  font-family: "din-condensed";
  src: local("din-condensed"),
    url(./assets/fonts/din-condensed.ttf) format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  font-family: "din-condensed", sans-serif;
  margin: 0;
}

input {
  font-family: "din-condensed", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  html {
    font-size: 12px;
  }
}
