.button {
  border: none;
  border-radius: 22px;
  box-shadow: 7px 7px 15px #00000029;
  background-color: #1cd4e3;
  cursor: pointer;
  padding: 0.8em 2em;
  position: relative;
}

.button:disabled {
  background-color: #a6dfe4;
  cursor: not-allowed;
}

.button:active {
  background-color: #01b8c8;
  box-shadow: 7px 7px 15px #1cd4e3;
}

.spinnerContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-left: -2em;
  margin-top: -0.8em;
  position: absolute;
  width: 100%;
}
