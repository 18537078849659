.container {
  background-color: black;
}

.header {
  align-items: center;
  color: white;
  display: flex;
  font-size: 3.5rem;
  height: 2em;
  justify-content: center;
  letter-spacing: 1.5px;
  margin: 0;
  text-align: center;
}

.text {
  border-bottom: 2px solid #1cd4e3;
  cursor: pointer;
  height: 1em;
}

.versionText {
  bottom: 10px;
  color: white;
  text-align: center;
  margin: 0;
  position: relative;
}

.plus {
  bottom: 0.125em;
  color: #1cd4e3;
  padding-left: 0.15em;
  position: relative;
}
