.container {
  display: inline;
}

.input {
  display: none;
}

.box {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 2px;
}

.label {
  padding-left: 8px;
  cursor: pointer;
}

.checkMark {
  bottom: -3px;
  left: -3px;
  position: absolute;
  width: 20px;
}
