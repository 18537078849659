.container {
  display: grid;
  grid-template-columns: 1fr 1fr 4fr;
  margin-top: 1em;
}

.header {
  border: 1px solid black;
  background-color: black;
  color: white;
  height: 2.5em;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 1em;
}

.entry {
  border: 1px solid black;
  height: 2.5em;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 1em;
}

@media (max-width: 700px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
