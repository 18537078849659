.container {
  display: grid;
  grid-template-rows: 20% 80%;
  min-height: 100vh;
  width: 100vw;
}

@media (max-width: 1000px) {
  .container {
    grid-template-rows: 13% 87%;
  }
}
