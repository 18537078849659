.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 11rem;
}

.label {
  font-size: 1.6rem;
  margin-bottom: 5px;
  text-align: center;
}

.input {
  border: solid 1px #dbdbdb;
  border-radius: 22px;
  box-shadow: 7px 7px 15px #00000029;
  font-size: 1.4rem;
  height: 3.5rem;
  padding-top: 5px;
  text-align: center;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #dbdbdb;
}

.error {
  bottom: -2.9rem;
  color: red;
  left: 1rem;
  position: absolute;
}
