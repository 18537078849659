.container {
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 22px;
  box-shadow: 7px 7px 15px #00000029;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 3.5rem;
  margin-top: 1rem;
  position: relative;
  width: 100%;
}

.dayBasedContainer {
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 22px;
  box-shadow: 7px 7px 15px #00000029;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 3.5rem;
  margin-top: 1rem;
  position: relative;
  width: 100%;
}

.value {
  align-self: center;
  font-size: 1.5rem;
  justify-self: center;
  margin: 0;
  margin-top: 7px;
  text-transform: capitalize;
}

.dayBasedValue {
  align-self: center;
  font-size: 1.5rem;
  justify-self: center;
  margin: 0;
  margin-top: 7px;
  text-transform: capitalize;
}

.timeBasedValue {
  align-self: center;
  font-size: 1.5rem;
  justify-self: center;
  margin: 0;
  margin-top: 7px;
  text-transform: capitalize;
}

.timeBasedValue:first-of-type {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 50%;
}

.routeValue {
  align-self: center;
  justify-self: center;
}

.arrow {
  fill: #b5b5b5;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 1.5rem;
}

.remove {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 6px;
  transition: all 0.3s linear;
  width: 1.5rem;
}

.remove:hover {
  fill: red;
}

@media (max-width: 500px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }

  .value:nth-of-type(2) {
    display: none;
  }

  .value:nth-of-type(3) {
    display: none;
  }

  .timeBasedValue:first-of-type {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 50%;
  }

  .arrow {
    display: none;
  }
}
