.container {
  width: 100%;
}

.sectionHeader {
  font-size: 5rem;
  margin: 0;
  padding: 2rem 4rem;
}

.calculationContainer {
  background-color: #e6fafc;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 3fr 2fr 2fr;
  padding: 2rem 4rem;
  width: 100%;
}

.totalContainer {
  background-color: #1cd4e3;
  border-radius: 22px;
  box-shadow: 7px 7px 15px #00000029;
  color: white;
}

.basicContainer {
  background-color: white;
  border-radius: 22px;
  box-shadow: 7px 7px 15px #00000029;
}

.variableContainer {
  background-color: white;
  border-radius: 22px;
  box-shadow: 7px 7px 15px #00000029;
}

.boxHeader {
  font-size: 2.5rem;
  text-align: center;
}

.paymentContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.euro {
  font-size: 3rem;
  position: relative;
  top: 0.5rem;
}

.amount {
  font-size: 8.5rem;
}

.cents {
  font-size: 3rem;
  height: 100%;
  position: relative;
  top: 4.5rem;
}

.smallEuro {
  font-size: 2rem;
  position: relative;
  top: 0.5rem;
}

.smallAmount {
  font-size: 7.5rem;
}

.smallCents {
  font-size: 2rem;
  height: 100%;
  position: relative;
  top: 4.5rem;
}

.buttonContainer {
  background-color: #e6fafc;
  padding: 2rem 4rem;
}

@media (max-width: 600px) {
  .calculationContainer {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

@media (max-width: 500px) {
  .sectionHeader {
    padding: 2rem 2rem;
  }

  .calculationContainer {
    padding: 2rem 2rem;
  }

  .buttonContainer {
    padding: 2rem 2rem;
  }
}
