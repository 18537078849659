.select {
  align-items: center;
  appearance: none;
  background: linear-gradient(to right, #fff 80%, #1cd4e3 80%);
  border: none;
  border-radius: 22px;
  box-shadow: 7px 7px 15px #00000029;
  color: #dedede;
  cursor: pointer;
  display: flex;
  font-size: 1.9rem;
  height: 3em;
  padding-left: 1.5em;
  padding-top: 10px;
  position: relative;
  width: 30vw;
  z-index: 3;
}

.select:focus {
  outline: none;
}

.caret {
  cursor: pointer;
  fill: white;
  position: absolute;
  right: 1.7rem;
  top: 0.5rem;
  width: 2.5rem;
}

.optionContainer {
  background: white;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  position: absolute;
  margin: 0;
  margin-top: -16px;
  width: 30vw;
  z-index: 2;
}

.option {
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer;
  font-size: 1.9rem;
  list-style: none;
  padding-bottom: 0.5em;
  padding-left: 1.5em;
  padding-top: 0.5em;
  position: relative;
  right: 40px;
  width: 30vw;
}

.option:hover {
  background-color: rgba(28, 212, 227, 0.2);
}

.option:last-of-type {
  border-bottom: none;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
}

.option:first-of-type {
  padding-top: 1.4em;
}

@media (max-width: 700px) {
  .select {
    height: 3.5em;
    width: 60vw;
  }

  .caret {
    top: 1.1rem;
  }

  .optionContainer {
    width: 60vw;
  }

  .option {
    width: 60vw;
  }

  .optionContainer {
    z-index: 4;
  }
}

@media (max-width: 400px) {
  .caret {
    right: 1rem;
  }
}
