.container {
  display: grid;
  grid-template-rows: 2fr 1fr;
}

.title {
  border: 1px solid black;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.titleBox {
  background-color: black;
  padding: 0.5em;
  color: white;
  font-size: 1.2rem;
  letter-spacing: 3px;
}

.headers {
  display: grid;
  /* grid-template-columns: 1fr 2fr 3fr 1fr 1fr; */
  grid-template-columns: 1fr 2fr 3fr 1fr;
}

.headerBox {
  align-items: center;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  width: 100%;
}
